<template>
  <section class="block main-categories-block">
    <div class="container">
      <div class="block-inner main-categories-inner">
        <div class="block-content main-categories-content">
          <ul class="main-categories">
            <li
              v-for="(category, idx) in items"
              :key="`main-category-${idx}`"
              class="main-category"
            >
              <NuxtImg
                :src="String(category.image)"
                :alt="category.title"
                width="420"
                height="325"
                loading="lazy"
                class="main-category-image"
              />
              <p class="main-category-title">
                {{ category.title }}
              </p>
              <nuxt-link
                class="btn outlined main-category-link"
                :to="category.link"
              >
                {{ category.link_text }}
              </nuxt-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type {IBlockDefaultProps} from "#sitis/internal/models/common";
import type {ComputedRef} from "vue";

const props = defineProps<IBlockDefaultProps>();

const items: ComputedRef<any[]> = computed(() => {
  console.log('props: ', props);
  return props.values?.blocks || []
})
</script>

<style lang="scss">
.main-categories {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.main-category {
  min-height: 325px;
  max-height: 325px;
  position: relative;
  padding: 30px 40px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.main-category-image {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 325px;
  object-fit: cover;
}

.main-category-title {
  font-size: 24px;
  line-height: 31px;
  color: rgb(255 255 255 / 80%);
}

.btn.outlined.main-category-link {
  border: 2px solid rgb(255 255 255 / 80%);
  font-size: 18px;
  line-height: 23px;
  padding: 7px 19px;
  color: rgb(255 255 255 / 80%);
  border-radius: 15px;
  box-shadow: 0 0 5px 0 rgb(255 255 255 / 0%);

  &:hover {
    box-shadow: 0 0 5px 0 rgb(255 255 255 / 100%);
  }
}

@media (max-width: 1023px) {
  .main-categories-block {
    .container {
      padding: 0;
    }
  }

  .main-categories {
    grid-template-columns: repeat(2, 1fr);
  }

  .main-category {
    min-height: 200px;
    max-height: 200px;
    padding: 16px;
    gap: 10px;

    &:last-child:not(:nth-child(2n)) {
      grid-column: 1 / 3;
    }
  }

  .main-category-image {
    max-height: 200px;
  }
}

@media (max-width: 839px) {
  .main-categories-block {
    & .block-inner {
        padding: 1px 0;
      }
    & .main-categories {
        gap: 3px;
        padding-right: 3px; // подгонка под верхний блок
      }
  }
}

@media (max-width: 659px) {
  .main-category {
    min-height: 170px;
    max-height: 170px;
  }

  .main-category-title {
    font-size: 14px;
    line-height: 18px;
  }

  .btn.outlined.main-category-link {
    font-size: 12px;
    line-height: 16px;
    padding: 9px 19px;
  }
}
</style>
